<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"></Breadcrumb>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>广告位名称：</label>
            <el-input v-model="params.title" placeholder="分类名称"></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-alert class="width-434" title="最多允许添加6个轮播图" type="warning"></el-alert>
        <el-row class="m-bottom-10" type="flex" justify="space-between">
          <div></div>
          <div class="right-btn">
            <el-button @click="adsenseVisible = true; action = 'add'" class="bg-gradient">新建广告位</el-button>
          </div>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="title" label="广告位名称"></el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-image class="width-70" :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="跳转类型">
            <template slot-scope="scope">{{ scope.row.type | filterType(that) }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button v-if="scope.row.sort !== 1" @click="handleMove(scope.row.id, 0)" type="text">上移</el-button>
              <el-button v-if="scope.row.sort !== tableData.length" @click="handleMove(scope.row.id, 1)" type="text">下移</el-button>
              <el-button @click="handleDel(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
    <el-dialog title="新建" :visible.sync="adsenseVisible" width="550px" :before-close="handleClose">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="名称" prop="title">
          <el-input v-model="ruleForm.title" class="width-280" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="imageUrl">
          <el-upload
              class="avatar-uploader" ref="cover-uploader" :show-file-list="false"
              action="#" :auto-upload="false" :on-change="handleUpload">
            <img width="178px" v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="ruleForm.type" class="width-280 m-bottom-10" placeholder="选择类型">
            <el-option v-for="(val, key) in { '1': '商品', '2': '搜索词', '3': '其他页面' }"
                       :key="key" :label="val" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <div class="m-left-40 type-box">
          <el-form-item v-if="ruleForm.type === '1'" prop="productId">
            <el-button @click="selectedGoods" class="m-right-5" type="primary">选择商品</el-button>
            <span style="color: #F56C6C">{{ ruleForm.productId ? '' : '未选择' }}</span>
          </el-form-item>
          <el-form-item v-if="ruleForm.type === '2'" prop="searchTerm">
            <el-input v-model="ruleForm.searchTerm" class="width-280" placeholder="输入搜索词"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleForm.type === '3'" prop="url">
            <el-input v-model="ruleForm.url" class="width-280" placeholder="输入链接地址"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button @click="adsenseVisible = false">取消</el-button>
        <el-button @click="handleSave" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="goodsVisible" width="650px">
      <el-row class="m-bottom-20">
        <label>商品名称：</label>
        <el-input v-model="name" clearable class="width-200 m-right-5"></el-input>
        <el-button @click="() => { this.goodsPage = 1; getProductList() }" type="primary">搜索</el-button>
      </el-row>
      <div class="goods-box">
        <el-table ref="goodsForm" :data="goodsData" :stripe="true" border fit
                  highlight-current-row @current-change="(val) => this.productId = val.id">
          <el-table-column prop="id" label="商品ID" width="180"></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" @current-change="changeGoodsCurrPage" :page-size="10" :total="goodsTotal"></el-pagination>
      </div>
      <span slot="footer">
        <el-button @click="goodsVisible = false">取消</el-button>
        <el-button @click="saveCheckGoods" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, addAdsense, delAdsense, moveAdsense } from '@/api/shop/conf/adsense';
import { beforeUpload, getDict } from "@/common/js/common";
import { uploadImage } from '@/api/shop/goods/goods'
import { mapState } from 'vuex';
import { getProductPage } from "@/api/shop/conf/classify";
export default {
  name: 'adsense',
  data() {
    return {
      crumbs: new Map([
          ['特惠商城'], ['页面配置'], ['广告位管理']
      ]),
      that: this,
      params: {
        limit: 6,
        page: 1
      },
      total: 0,
      loading: true,
      tableData: [],
      adsenseVisible: false,
      ruleForm: {
        id: '',
        title: '',
        imageUrl: '',
        type: '1',
        status: '1',
        url: '',
        productId: '',
        searchTerm: ''
      },
      rules: {
        title: [{ required: true, message: '名称不能为空！' }],
        imageUrl: [{ required: true, message: '图片不能为空！' }],
        productId: [{ required: true, message: '商品不能为空！' }]
      },
      action: 'add',
      goodsVisible: false,
      name: '',
      goodsTotal: 0,
      goodsPage: 1,
      goodsData: [],
      productId: '',
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    getDict('shop-jump-type')
    this.getAdsenseList()
    this.getProductList()
  },
  methods: {
    // 获取广告位列表
    getAdsenseList() {
      const params = { ...this.params, companyId: this.hotelInfo.storeId, hotelId: this.hotelInfo.id };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(params).then(({ success, total, records }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 查询广告位
    handleQuery(bool) {
      this.params.page = 1
      if (bool) return this.getAdsenseList()
      this.params.title = ''
    },
    // 上传图片
    handleUpload(file) {
      const error = beforeUpload(file)
      if (error) return this.$message.error(error)
      const params = new FormData()
      params.set('file', file.raw)
      params.set('keyPrefix', 'pms/images')
      params.set('hotelId', this.hotelInfo.id)
      uploadImage(params).then(({ success, records }) => {
        if (!success) return
        this.ruleForm.imageUrl = records.url
        this.$message({ message: '上传成功', type: 'success' })
      })
    },
    // 选择商品
    selectedGoods() {
      this.goodsVisible = true
      if (this.action === 'edit') {
        this.$nextTick(() => this.$refs.goodsForm.setCurrentRow(this.goodsData.find(i => i.id === this.ruleForm.productId)))
      }
    },
    // 获取商品列表
    getProductList() {
      const query = `?limit=10&page=${ this.goodsPage }`;
      const params = { name: this.name, status: 'PUTAWAY', hotelId: this.hotelInfo.id };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getProductPage(query, params).then(({ success, records, total }) => {
        if (!success) return;
        this.goodsTotal = total
        this.goodsData = records
      })
    },
    // 改变商品当前页
    changeGoodsCurrPage(num) {
      this.goodsPage = num
      this.getProductList()
    },
    // 保存选中商品
    saveCheckGoods() {
      this.goodsVisible = false
      this.ruleForm.productId = this.productId
    },
    // 编辑广告位
    handleEdit(row) {
      this.action = 'edit'
      this.adsenseVisible = true
      for (const k in this.ruleForm) k in row && (this.ruleForm[k] = row[k]);
    },
    // 处理保存
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const params = {
          ...this.ruleForm,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        }
        if (this.action === 'add') delete params.id
        addAdsense(params, this.action).then(({ success }) => {
          if (!success) return;
          this.handleClose()
          this.getAdsenseList()
          this.$message({ message: '保存成功！', type: 'success' })
        })
      })
    },
    // 关闭会话框
    handleClose() {
      this.adsenseVisible = false
      for (const k in this.ruleForm) {
        if (k === 'type' || k === 'status') this.ruleForm[k] = '1'
        else this.ruleForm[k] = ''
      }
    },
    // 删除广告位
    handleDel(id) {
      this.$confirm('是否确认删除该条目？小程序首页将不会在显示', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        delAdsense(`/${ id }`).then(({ success }) => {
          if (!success) return;
          this.getAdsenseList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    },
    // 处理排序
    handleMove(id, step) {
      const params = { id, step }
      moveAdsense(params).then(({ success }) => {
        if (!success) return;
        this.getAdsenseList()
        this.$message({ message: '排序成功！', type: 'success' })
      })
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.getAdsenseList()
        this.getProductList()
      }
    }
  },
  filters: {
    filterType(val, that) {
      if (val && that.dictData['shop-jump-type']) return that.dictData['shop-jump-type'][val] || val
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  .el-form{
    padding: 0 30px;
    .el-form-item:nth-child(3) { margin-bottom: 0 }
    ::v-deep{ .el-form-item__error{ margin-left: 50px } }
    .type-box{
      ::v-deep .el-form-item__error{ margin-left: 0 }
    }
  }
}
</style>
