import request from "@/api/service";
import qs from 'qs';

const api = {
   page: '/goods/homeCarousel/getPage',
   add: '/goods/homeCarousel/add',
   edit: '/goods/homeCarousel/edit',
   delete: '/goods/homeCarousel/delete',
   move: '/goods/homeCarousel/move'
}

export function getPage(params) {
   return request({
      url: api.page,
      method: 'GET',
      params
   })
}

export function addAdsense(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delAdsense(query) {
   return request({
      url: api.delete + query,
      method: 'POST'
   })
}

export function moveAdsense(params) {
   return request({
      url: api.move,
      method: 'POST',
      data: qs.stringify(params)
   })
}
